import base64 from 'base64-js';

export  function decodeJwt(token: string) {
    let payload = token.split('.')[1];
    switch (payload.length % 4) {
      case 0:
        break;
      case 2:
        payload += '==';
        break;
      case 3:
        payload += '=';
        break;
      default:
        throw new Error('Invalid base64 payload length: ' + payload);
    }
    const bytes = base64.toByteArray(payload);
    const size = 0x8000;
    const chunks = [];
    for (let i = 0, ii = bytes.length; i < ii; i += size) {
      chunks.push(
          // eslint-disable-next-line prefer-spread
          String.fromCharCode.apply(String, bytes.subarray(i, i + size) as any));
    }
    const str = decodeURIComponent(escape(chunks.join('')));
    return JSON.parse(str);
  }
