



























































































































































































































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { ILetnaPriprava, IReference, IVsebinskiSklop, letnaPripravCreate, letnaPripravPreverjanjeCreate, letnaPripravSklopCreate } from "@/model/priprava";
import { GetPredmeti } from "@/api";
import { IPredmet, ITema } from "@/model";
import TextEditor from "@/components/priprava/priprava-text-editor.vue";
import TemaList from '@/components/tema-list.vue';
import { GetLetnaPriprava, PostLetnaPripravaExport, PostLetnaPripravaStore } from "@/api/priprava";

@Component({ components: { TextEditor, TemaList } })
export default class LetnaPriprava extends Vue {
    @Prop()
    id!: number | null;

    letnaPriprava: ILetnaPriprava = letnaPripravCreate();
    expanded: any[] = [];
    expandedPreverjanje: any[] = [];
    loading: boolean = false;
    pagination = { rowsPerPage: 50 };

    stepForm: object = {
        step: 1,
        done1: false,
        done2: false,
        done3: false
    };

    columns = [
        { name: 'sklop', label: 'Sklop', field: 'sklop', align: "left" },
        { name: 'obdobje', align: 'center', label: 'Obdobje', field: 'obdobje', sortable: true },
        { name: 'ure', label: 'Predv. št. ur', field: 'stUr', },
    ];

    columnsPreverjanja = [
        { name: 'ocenjevalnoObdobje', label: 'Ocenjevalno obdobje', field: 'ocenjevalnoObdobje', align: "left" },
        { name: 'obdobje', align: 'center', label: 'Časovno obdobje', field: 'obdobje', sortable: true },
        { name: 'vrsta', label: 'Vrsta', field: 'vrsta' },
        { name: 'nacini', label: 'Načini', field: 'nacini' },

    ];

    async mounted() {
        this.loading = true;

        if(!this.letnaPriprava.ucitelj && (this.$store as any).state.auth?.user?.ime)
            this.letnaPriprava.ucitelj = (this.$store as any).state.auth?.user?.ime;
        if(!this.letnaPriprava.zavod && (this.$store as any).state.auth?.user?.zavod)
            this.letnaPriprava.zavod = (this.$store as any).state.auth?.user?.zavod;

        GetPredmeti()
            .then((d) => this.$store.commit("setInitialData", d.data.predmeti))
            .then(d => this.getData())
            .finally(() => (this.loading = false));
    }

    get programi() {
        const pr = [
            ...new Set(this.$store.state.predmeti.map((p: IPredmet) => p.program)),
        ] as string[];
        pr.sort((a: string, b: string) => (a < b ? -1 : a > b ? 2 : 0));
        return pr;
    }

    get predmeti() {
        return this.predmetList(this.$store.state.selectedProgram);
    }

    get isLoggedIn() {
        return (this.$store as any).state.auth.token != null;
    }

    get auth() {
        return (this.$store as any).state.auth;
    }

    @Watch('isLoggedIn')
    loggedInChanged() {
        this.letnaPriprava.ucitelj = this.auth?.user?.ime;
        this.getData();
    }

    async getData() {
        if (this.id && this.isLoggedIn) {
            const response = await GetLetnaPriprava(this.id);
            this.letnaPriprava = response.data;
            this.letnaPriprava.id = this.id;
            this.$store.commit('setSelectedProgram', this.letnaPriprava.program);
            const predmet = this.$store.state.predmeti.find((p: any) => p.title.toLowerCase() == this.letnaPriprava.predmet.toLowerCase() && p.program == this.letnaPriprava.program);
            //const predmet = this.predmeti.find(p => p.title.toLowerCase() == this.letnaPriprava.predmet.toLowerCase());

            this.$store.commit('setSelectedPredmet', predmet);
        }
    }


    predmetList(program: string): IPredmet[] {
        if (program == null) return [];
        const predmetiPrograma = this.$store.state.predmeti.filter(
            (p: IPredmet) => p.program.toLowerCase() == program.toLowerCase()
        );
        predmetiPrograma.sort((a: IPredmet, b: IPredmet) =>
            a.title < b.title ? -1 : a.title > b.title ? 2 : 0
        );
        return predmetiPrograma;
    }

    addSklop() {
        const newId = this.letnaPriprava.sklopi.reduce((agg, curr) => Math.max(agg, curr.id), 0) + 1;
        this.letnaPriprava.sklopi.push(letnaPripravSklopCreate(newId));
    }

    addPreverjanje() {
        const newId = this.letnaPriprava.preverjanja.reduce((agg, curr) => Math.max(agg, curr.id), 0) + 1;
        this.letnaPriprava.preverjanja.push(letnaPripravPreverjanjeCreate(newId));
    }

    sklopRowClick(props: any) {
        if (this.expanded.length == 1 && this.expanded[0] == props.row.id) {
            this.expanded = [];
            return;
        } else this.expanded = [props.row.id];
        //props.expand = !props.expand;
        console.log(props.row);
        if (props.row.ref && props.row.ref.text) {
            const predmet = this.predmeti.find(p => p.title == props.row.ref.predmet);
            console.log(predmet);
            if (predmet != null) {
                this.$store.commit("setSelectedPredmet", predmet);
                const tema = this.$store.state.selectedPredmet?.teme?.find((t: ITema) => t.id == props.row.ref.temaId);
                this.$store.commit("setSelectedTema", tema);
            }
            // this.$store.commit("setSelectedSklop", props.row.ref.sklopId);
            //   this.$store.commit("setSelectedPodsklop", props.row.ref.podsklopId);
        }

    }

    rowPreverjanjeClick(props: any) {
        if (this.expandedPreverjanje.length == 1 && this.expandedPreverjanje[0] == props.row.id)
            this.expandedPreverjanje = [];
        else this.expandedPreverjanje = [props.row.id];
    }


    sklopSelect(row: IVsebinskiSklop, data: IReference) {
        row.sklop = data.text || "";
        row.ref = data;
    }

    setPredmet(predmetNziv: string) {
        this.letnaPriprava.predmet = predmetNziv;
        const predmet = this.predmeti.find(p => p.title.toLowerCase() == predmetNziv.toLowerCase());
        this.$store.commit('setSelectedPredmet', predmet);
    }

    deactivatePicker() {
        this.$store.commit("priprava/setPicker", null);
    }

    deleteSklop(sklop: any) {
        this.$q.dialog({
            title: 'Brisanje sklopa',
            message: 'Ali želite izbrisati izbrani sklop?',
            cancel: true,
            persistent: true
        }).onOk(() => {
            console.log(this.letnaPriprava.sklopi);
            const index = this.letnaPriprava.sklopi.indexOf(sklop);
            this.letnaPriprava.sklopi.splice(index, 1);
        }).onOk(() => {
            // console.log('>>>> second OK catcher')
        }).onCancel(() => {
            // console.log('>>>> Cancel')
        }).onDismiss(() => {
            // console.log('I am triggered on both OK and Cancel')
        });
    }

    async exportClick() {
        await PostLetnaPripravaExport(this.letnaPriprava);
        this.$q.notify({ message: 'Podatki so bili uspešno izvoženi', type: 'positive', position: 'top' });
    }

    async storeClick() {
        const response = await PostLetnaPripravaStore(this.letnaPriprava);
        if (response.data.success) {
            this.letnaPriprava.id = response.data.id;
            this.$q.notify({ message: 'Letna priprava je bila uspešno shranjena', type: 'positive', position: 'top' });
        }
    }
    getLabel(column: string) {
        return this.$store.getters['priprava/dodatniStolpciLetna'][column]?.label ?? column;
    }
}
