















































































































































































import { Component, Vue } from 'vue-property-decorator';
import { GetPredmeti, GetPredmet, Search } from '@/api';
import Logo from '@/components/layout/logo.vue';
import TemaList from '@/components/tema-list.vue';
import { ISekcija, IPredmet } from '@/model';
import trim from '@/utils/trim';


@Component({ components: { Logo, TemaList } })
export default class NacrtContainer extends Vue {
    loading: boolean = false;
    searchTerm: string = "";

    searchSelectedProgram: string = "";
    searchSelectedPredmet: string = "";
    searchResults: any[] = [];
    tab: string = "filters";
    baseAddress = process.env.VUE_APP_BASE_API_URL;

    async mounted() {
        this.loading = true;
        GetPredmeti().then(d => this.$store.commit("setInitialData", d.data.predmeti)).finally(() => this.loading = false);
    }

    get programi() {
        const pr = [...new Set(this.$store.state.predmeti.map((p: IPredmet) => p.program))] as string[];
        pr.sort((a: string, b: string) => a < b ? -1 : (a > b ? 2 : 0));
        return pr;
    }

    get predmeti() {
        return this.predmetList(this.$store.state.selectedProgram);
    }

    get searchPredmeti() {
        return this.predmetList(this.searchSelectedProgram);
    }

    predmetList(program: string) {
        if (program == null) return [];
        const predmetiPrograma = this.$store.state.predmeti.filter((p: IPredmet) => p.program.toLowerCase() == program.toLowerCase());
        predmetiPrograma.sort((a: IPredmet, b: IPredmet) => a.title < b.title ? -1 : (a.title > b.title ? 2 : 0));
        return predmetiPrograma;
    }

    get showRazrediFIlter() {
        return this.$store.state.selectedPredmet && this.$store.state.selectedPredmet.imaRazrede;
    }

    get sekcijeNames() {

        let sekcijeNames: any = [];
        if (this.$store.state.selectedPodsklop) {
            return this.$store.state.selectedPodsklop.sekcije.map((s: ISekcija) => trim(s.title));
        }
        if (this.$store.state.selectedSklop) {
            let podsklopiSekcije: string[] = [];
            if (this.$store.state.selectedSklop.podsklopi && this.$store.state.selectedSklop.podsklopi.length > 0) {
                podsklopiSekcije = this.sekcijeSklopa(this.$store.state.selectedSklop.podsklopi, podsklopiSekcije);
            }
            const sekciejSklopa = this.$store.state.selectedSklop.sekcije.map((s: ISekcija) => trim(s.title));
            return [...new Set([...podsklopiSekcije, ...sekciejSklopa])];
        }
        if (!this.$store.state.selectedTema) {
            if (!this.$store.state.selectedPredmet || !this.$store.state.selectedPredmet.isLoaded) return [];
            return this.$store.state.selectedPredmet.sekcije.map((s: ISekcija) => trim(s.title));
        }

        //selectirana je celotna tema
        const sklopiTeme = this.$store.state.selectedTema.sekcije;
        if (sklopiTeme) {
            sekcijeNames = sklopiTeme.map((s: any) => trim(s.title));
        }
        sekcijeNames = this.sekcijeSklopa(this.$store.state.selectedTema.sklopi, sekcijeNames);
        return new Set(sekcijeNames);
    }

    get rightDrawerWidth() {
        if ((this as any).windowWidth > 1400) return 500;
        if ((this as any).windowWidth > 1199) return 400;
        return 300;
    }

    async setPredmet(predmet: any) {
        /*
        if (!predmet.isLoaded) {
            this.loading = true;
            try {
                const returned = await GetPredmet(predmet.id, "");

                predmet = { ...JSON.parse(returned.data.data), id: returned.data.id, isLoaded: true };

                this.$store.commit("addPredmet", predmet);
            } finally {
                this.loading = false;
            }
        }*/

        this.$store.commit("setSelectedPredmet", predmet);
        this.$store.commit("setSelectedTema", null);
        this.$store.commit("setSelectedSklop", null);
        this.$store.commit("setSelectedPodsklop", null);
        window.scrollTo(0, 0);
        return predmet;
    }
    setProgram(program: string) {
        this.$store.commit('setSelectedProgram', program);
        if (this.$store.state.selectedPredmet && this.$store.state.selectedPredmet.program != program)
            this.$store.commit("setSelectedPredmet", null);
    }

    selectTema(tema: any) {
        this.$store.commit("setSelectedTema", tema);
        this.$store.commit("setSelectedSklop", null);
        this.$store.commit("setSelectedPodsklop", null);
        window.scrollTo(0, 0);
    }
    selectSklop(sklop: any) {
        this.$store.commit("setSelectedSklop", sklop);
        this.$store.commit("setSelectedPodsklop", null);
        window.scroll(0, 0);
    }
    selectPodsklop(sklop: any) {
        this.$store.commit("setSelectedPodsklop", sklop);
        window.scroll(0, 0);
    }
    setRazredFilter(range: { min: number, max: number }) {
        this.$store.commit("setFilter", { razred: range });
    }
    setVioFilter(value: boolean) {
        this.$store.commit("setFilter", { vio: value });
    }

    sekcijeSklopa(array: any[], existingNames: string[]) {
        existingNames = existingNames || [];
        return array.reduce((agg: any[], curr: any) => {
            if (curr) {
                const d = curr.sekcije.map((s: any) => trim(s.title));
                agg = [...agg, ...d];
            }

            return agg;
        }, existingNames);
    }

    isSelected(id: number) {
        return this.$store.state.selectedSearchResult && this.$store.state.selectedSearchResult.id == id;
    }

    searchResultClick(result: any) {
        setTimeout(async () => {
            let predmet: IPredmet = this.$store.state.predmeti.find((p: IPredmet) => p.id == result.predmetId);

            if (!predmet.isLoaded) {
                this.loading = true;
                try {
                    const returned = await GetPredmet(predmet.id);

                    predmet = { ...JSON.parse(returned.data.data), id: returned.data.id, isLoaded: true, program: predmet.program };

                    this.$store.commit("addPredmet", predmet);
                } finally {
                    this.loading = false;
                }
            }

            this.$store.commit("setSelectedPredmet", predmet);
            this.$store.commit("setSelectedProgram", predmet.program);

            const tema = predmet.teme.find(t => t.id == result.temaId);
            this.$store.commit("setSelectedTema", tema);

            if (tema != null) {
                const sklop = tema.sklopi.find(t => t.id == result.sklopId);
                this.$store.commit("setSelectedSklop", sklop);
                if (sklop != null) {
                    const podsklop = sklop.podsklopi.find(t => t.id == result.podsklopId);
                    this.$store.commit("setSelectedPodsklop", podsklop);
                } else {
                    this.$store.commit("setSelectedPodsklop", null);
                }

            } else {
                this.$store.commit("setSelectedSklop", null);
            }
            this.$store.commit("setSelectedSearchResult", result);
            Vue.nextTick(() => {
                const elmnt = document.getElementById(result.id);
                if (elmnt) elmnt.scrollIntoView({ block: "center" });
                else { console.log("ne najdem"); }
            });
        }, 0);
    }

    async search() {
        if (this.searchTerm)
            this.searchResults = (await Search(this.searchTerm, { program: this.searchSelectedProgram, predmet: this.searchSelectedPredmet })).data;
        else
            this.searchResults = [];

    }
    selectSearchProgram(result: any) {
        this.searchSelectedProgram = result.program;
        this.search();
    }
    selectSearchPredmet(result: any) {
        this.searchSelectedPredmet = result.predmet;
        this.search();
    }

}
