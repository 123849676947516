



































































import { Component, Vue, Watch } from "vue-property-decorator";
import { GetLetnaPripravaList, GetSprotnaPripravaList } from "@/api/priprava";
import { ILetnaPriprava } from "@/model/priprava";

@Component({ components: {} })
export default class LetnaPriprava extends Vue {

    expanded: any[] = [];
    expandedPreverjanje: any[] = [];
    loading: boolean = false;
    loadingSprotne: boolean = false;
    pagination = { rowsPerPage: 50 };
    priprave: any[] = [];
    tab: string = "letne";
    sprotnePriprave: any[] = [];

    async mounted() {
        this.loading = true;
        this.loadingSprotne = true;
        this.getData();
    }

    get isLoggedIn() {
        return (this.$store as any).state.auth.token != null;
    }

    @Watch('isLoggedIn')
    loggedInChanged() {
        this.getData();
    }

    async getData() {
        if (this.isLoggedIn) {
            GetLetnaPripravaList()
                .then((d) => this.priprave = d.data)
                .finally(() => (this.loading = false));
            GetSprotnaPripravaList()
                .then((d) => this.sprotnePriprave = d.data)
                .finally(() => (this.loadingSprotne = false));
        }
    }

    rowClick(evt: any, row: ILetnaPriprava, index: any) {
        this.$router.push({ name: 'letna-priprava', params: { id: row.id?.toString() ?? "" } });
        console.log(row);
    }
    rowSprotnaClick(evt: any, row: ILetnaPriprava, index: any) {
        this.$router.push({ name: 'sprotna-priprava', params: { id: row.id?.toString() ?? "" } });
        console.log(row);
    }


}
