








































import { Component, Vue } from 'vue-property-decorator';
import Logo from './components/layout/logo.vue';
import TemaList from './components/tema-list.vue';
import { ITema } from './model';

@Component({ components: { Logo, TemaList } })
export default class App extends Vue {
    toggleLeftDrawer() {
        this.$store.commit("ui/toggleLeftDrawer");
    }
    toggleRightDrawer() {
        this.$store.commit("ui/toggleRightDrawer");
    }

    async setPredmet(predmet: any) {

        this.$store.commit("setSelectedPredmet", predmet);
        this.$store.commit("setSelectedTema", null);
        this.$store.commit("setSelectedSklop", null);
        this.$store.commit("setSelectedPodsklop", null);
        window.scrollTo(0, 0);
        return predmet;
    }
    selectTema(tema: ITema) {
        this.$store.commit("setSelectedTema", tema ? tema : null);
        this.$store.commit("setSelectedSklop", null);
        this.$store.commit("setSelectedPodsklop", null);
        window.scrollTo(0, 0);
        return tema?.title;
        //if (tema)
        //  Vue.set(this.expanded, tema.id, true);
    }
}
