var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"q-pa-md letna-priprava"},[_c('span',{staticClass:"text-h4"},[_vm._v("Seznam priprav")]),_c('div',{staticClass:"col-12 q-pa-sm"}),_c('q-tabs',{staticClass:"text-grey",attrs:{"dense":"","active-color":"primary","indicator-color":"primary","align":"justify","narrow-indicator":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('q-tab',{attrs:{"name":"letne","label":"Letne priprave"}}),_c('q-tab',{attrs:{"name":"sprotne","label":"Sprotne priprave"}})],1),_c('q-separator'),_c('q-tab-panels',{attrs:{"animated":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('q-tab-panel',{attrs:{"name":"letne"}},[_c('q-table',{attrs:{"title":"Seznam letnih priprav","data":_vm.priprave,"row-key":"id","binary-state-sort":"","expanded":_vm.expanded,"pagination":_vm.pagination,"bordered":"","flat":"","loading":_vm.loading,"columns":[
                    { name: 'program', label: 'Program', field: 'program', align: 'left' },
                    { name: 'predmet', label: 'Predmet', field: 'predmet', align: 'left' },
                    { name: 'solskoLeto', label: 'solskoLeto', field: 'solskoLeto', align: 'left' },
                    { name: 'razred', label: 'Razred', field: 'razred', align: 'left' } ]},on:{"update:expanded":function($event){_vm.expanded=$event},"row-click":_vm.rowClick},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"col-9 q-table__title"},[_vm._v("Seznam letnih priprav")]),_c('div',{staticClass:"col-3"},[_c('q-btn',{staticClass:"float-right",attrs:{"unelevated":"","label":"nova priprava"},on:{"click":function($event){return _vm.$router.push({ name: 'letna-priprava' })}}})],1)]},proxy:true}])})],1),_c('q-tab-panel',{attrs:{"name":"sprotne"}},[_c('q-table',{attrs:{"title":"Seznam sprotnih priprav","data":_vm.sprotnePriprave,"row-key":"id","binary-state-sort":"","expanded":_vm.expanded,"pagination":_vm.pagination,"bordered":"","flat":"","loading":_vm.loading,"columns":[
                    { name: 'program', label: 'Program', field: 'program', align: 'left' },
                    { name: 'predmet', label: 'Predmet', field: 'predmet', align: 'left' },
                    { name: 'sklop', label: 'Sklop', field: 'sklop', align: 'left' },
                    { name: 'razred', label: 'Razred', field: 'razred', align: 'left' } ]},on:{"update:expanded":function($event){_vm.expanded=$event},"row-click":_vm.rowSprotnaClick},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"col-9 q-table__title"},[_vm._v("Seznam sprotnih priprav")]),_c('div',{staticClass:"col-3"},[_c('q-btn',{staticClass:"float-right",attrs:{"unelevated":"","label":"nova priprava"},on:{"click":function($event){return _vm.$router.push({ name: 'sprotna-priprava' })}}})],1)]},proxy:true}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }