import './style/quasar.styl';
import 'quasar/dist/quasar.ie.polyfills';

import './style/site.scss';

import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store/store';

import { Quasar, Notify, Dialog } from 'quasar';
import vueScrollTo from 'vue-scrollto';
import axios from 'axios';
import vueWindowSize from 'vue-window-size';


import '../quasar-init';


import 'katex/dist/katex.min.css';
import '@/style/editor.scss';

Vue.config.productionTip = false;
Vue.config.ignoredElements = [
    // Use a `RegExp` to ignore all elements that start with “nova-“
    // 2.5+ only
    /^iun-/
];
axios.defaults.headers.post['Content-Type'] = 'application/json';

Vue.use(Quasar, {
    plugins: { Notify, Dialog },
});
Vue.use(vueScrollTo);
Vue.use(vueWindowSize);

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount('#app');

//try loading login data form session storage
const loginData = sessionStorage.getItem("usr-data");
if (loginData) {
    const data = JSON.parse(loginData);
    if (data.expiration > Math.round((new Date()).getTime() / 1000))
        store.commit("auth/setData", data);
}