



















































































import { Component, Vue } from 'vue-property-decorator';
import { GetPredmeti, GetPredmet, Search } from '@/api';
import Logo from '@/components/layout/logo.vue';
import TemaList from '@/components/tema-list.vue';
import Nacrt from '@/views/nacrt/nacrt.vue';
import { ISekcija, IPredmet } from '@/model';
import trim from '@/utils/trim';
import PregledDejavnosti from "@/components/priprava/pregled-dejavnosti.vue";
import AppLogin from '@/components/auth/login.vue';
import { PostCreateUser, PostGetUser } from '@/api/auth';
import { decodeJwt } from '@/utils/jwt';

@Component({ components: { Logo, TemaList, Nacrt, PregledDejavnosti, AppLogin } })
export default class PripravaContainer extends Vue {
    loading: boolean = false;
    email: string = "";
    zavodiFiltered = [];
    user: any = null;
    userError: boolean = false;
    zavodi: any[] = [];
    lastZavodNeedle = "";
    ustanova: any = null;
    password: string = "";
    ime: string = "";

    async mounted() {
        this.loading = true;
        import("@/data/zavodi").then(d => this.zavodi = d.default).finally(() => this.loading = false);
        //GetPredmeti().then(d => this.$store.commit("setInitialData", d.data.predmeti)).finally(() => this.loading = false);
    }
    get isAuthorized() {
        const token = (this.$store as any).state.auth.token;
        if (token) {
            return decodeJwt(token)["can-manage-users"]=="true";
        }
        return false;

    }

    async getUser() {
        try {
            const resp = await PostGetUser(this.email);
            this.user = resp.data;
            if (resp.data)
                this.userError = false;
        } catch (ex) {
            console.log(ex);
            this.user = null;
            this.userError = true;
        }

    }

    private zavodiFilter(val: string, update: (fn: () => void) => void, abort: () => void) {
        update(() => {
            const needle = val.toLocaleLowerCase();
            if (this.lastZavodNeedle && needle.startsWith(this.lastZavodNeedle)) {
                const regexNeedle = "(?=.*" + needle.split(" ").join(")(?=.*") + ")";
                console.log(regexNeedle);
                this.zavodiFiltered = this.zavodiFiltered.filter((v: any) => v.naziv.toLocaleLowerCase().match(regexNeedle));
            } else {
                const regexNeedle = "(?=.*" + needle.split(" ").join(")(?=.*") + ")";
                this.zavodiFiltered = this.zavodi.filter(v => v.naziv.toLocaleLowerCase().match(regexNeedle)) as any;
            }

            this.lastZavodNeedle = needle;
        });
    }

    reset() {
        this.user = null;
        this.userError = false;
    }

    generatePassword() {
        var length = 8,
            charset = "abcdefghijklmnopqrstuvwxyz0123456789",
            retVal = "";
        for (var i = 0, n = charset.length; i < length; ++i) {
            retVal += charset.charAt(Math.floor(Math.random() * n));
        }
        this.password = retVal;
    }

    private setUstanova(data: any) {
        console.log(data);
        data = { ...data };
        if (!data.naslov) data.allowEditing = true;
        this.ustanova = data;
    }
    async addUser() {
        try {
            await PostCreateUser(this.email, this.password, this.ime, this.ustanova);
            this.email = "";
            this.user = null;
            this.userError = false;
            this.ustanova = "";
            this.$q.notify({ message: 'Spremembe so shranjene', type: 'positive' });
        } catch (e) {
            this.$q.notify({ message: 'Napaka pri prenosu podatkov na strežnik', type: 'negative' });
        }
    }

    resetPassword() {

    }


}
