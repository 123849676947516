
import Vue from 'vue';
import Router from 'vue-router';
import NacrtLayout from '@/views/nacrt/index.vue';
import Nacrt from '@/views/nacrt/nacrt.vue';
//import Editor from '@/views/editor.vuex';
import TextEditor from '@/components/editor/text-editor.vue';
import Priprave from '@/views/priprava/index.vue';
import SprotnaPriprave from './views/priprava/sprotna.vue';
import letnaPriprava from './views/priprava/letna.vue';
import letnaList from './views/priprava/letna-list.vue';
import ManageUsers from './views/users/manage.vue';

Vue.use(Router);

export default new Router({
    // mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            name: 'nacrt-layout',
            component: NacrtLayout,
            children: [
                {
                    path: '/',
                    name: 'nacrt',
                    component: Nacrt,
                },
            ]
        },
        /*  {
              path: '/edit',
              name: 'editor',
              component: Editor,
          },*/
        {
            path: '/edit',
            name: 'editor',
            component: TextEditor,
        },
        {
            path: '/users',
            name: 'users',
            component: ManageUsers,
        },
        {
            path: '/priprava',
            name: 'priprave',
            component: Priprave,
            children: [
                {
                    path: 'sprotna/:id?',
                    name: 'sprotna-priprava',
                    component: SprotnaPriprave,
                    props: true

                },
                {
                    path: 'letna/list',
                    name: 'letna-list',
                    component: letnaList,
                },
                {
                    path: 'letna/:id?',
                    name: 'letna-priprava',
                    component: letnaPriprava,
                    props: true
                },

            ]
        },
    ],
});
